.carousel-indicators [data-bs-target] {
    border-radius: 100%;
    min-height: 10px;
    max-width: 10px;
}

.carousel-control-prev {
    margin-top: 120px;
}

.carousel-control-next {
    margin-top: 120px;
}

.carousel-caption {
    @apply -translate-y-40
    flex
    justify-center
}

.carousel-caption h3 {
    @apply text-white/[.7] w-96 text-5xl -translate-y-14
}

.carousel-caption p {
    @apply text-lg mt-9
}
.fslide{
    width: 100%;
    height: auto;
    background-size: cover;
    background-position:60%;
    background-image: url('../../../public/img/slider3.avif');
}
.sslide{
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
    background-image: url('../../../public/img/slider2.jpg');
}
.tslide{
    width: 100%;
    height: auto;
    background-size: cover;
    background-position:70%;
    background-image: url('../../../public/img/slider1.jpg');
}