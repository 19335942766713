.ourworks {
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.item-top-img {
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
    /* background-attachment: fixed; */
    background-image: url('../../../public/img/footer.jpg');
}

.fal li {
    @apply items-center w-96 shadow-md shadow-black
}

.link:hover {
    @apply bg-red-950 text-white
}

.link{
    color: 'inherit';
    text-decoration: none;
}