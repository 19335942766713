@tailwind base;
@tailwind components;
@tailwind utilities;

.contact {
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.top-img {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-image: url('../../../public/img/footer2.jpg');
}