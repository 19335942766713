@tailwind base;
@tailwind components;
@tailwind utilities;

#navbar-item {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  color: azure;
}
#navbar-item:hover {
  @apply 
  text-red-950
}

.sidebar {
  box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}
#sb-navbar-item{
  @apply
  p-4
  px-8
  text-black
  w-full 
  text-3xl
}
#sb-navbar-item:hover{
  @apply
  bg-black/[.1]
}