.blog-comp {
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.blog-navbar-link:hover {
    color: 'inherit';
    text-decoration: none;
    @apply md:bg-black/[.3] text-white
}

.blog-navbar-link {
    color: 'inherit';
    text-decoration: none;
    @apply text-black p-3 rounded-l
}