#all {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

Link {
  color: 'inherit';
  text-decoration: none;
}

.footer {
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-image: url('../../../public/img/footer2.jpg');

  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.input {
  @apply text-white
}
.input:focus{
  @apply
  outline-none
}

input {
  @apply h-10 w-full p-3 bg-transparent border-b-2 rounded
}

textarea {
  @apply p-3 bg-transparent border-b-2 rounded
}

#bttn {
  @apply p-2 rounded bg-white text-red-950 border-2
}

#bttn:hover {
  @apply bg-transparent text-white
}