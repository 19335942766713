@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
}

#title {
  font-family: "Baskervville SC", serif;
  font-weight: 550;
  font-style: normal;
  font-weight: 400;
  /* font-size: 60px; */
  @apply
  md:text-6xl
  text-5xl
}
#fal-txt{
  font-size: 14px;
}
#text {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  /* font-size: 20px; */
  color: dimgrey;
  @apply
  md:text-xl
  text-sm
}

#fal-tit{
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
#fal-ttl{
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
#fal li {
  @apply w-96
}

#fal li button:hover {
  @apply bg-red-950 text-white
}

.cross-line {
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: rgb(69 10 10);
  /* Çizginin rengini ayarlayın */
  width: 0;
  transition: width 0.3s ease;
  @apply mt-4 mr-3
}

.cross-line-hover {
  width: 87%;
}

#nasıl-çalışıyoruz {
  background-image: url('../../../public/img/bgimg.jpg');
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  /* opacity: 70%; */
  background-attachment: fixed;
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: rgb(207, 206, 206);
}
#nçt{
  font-weight: 600;
  color: aliceblue;
}
#nçbt{
  font-family: "Baskervville SC", serif;
  font-weight: 550;
  font-style: normal;
  color: aliceblue;
}
.items-bg{
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  /* background-attachment: fixed; */
  background-image: url('../../../public/img/slider8.jpg');
}